import dayjs from 'dayjs'
import { useRouter } from 'next/router'
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import ContentLoader from 'react-content-loader'
import { ISbStoryData } from 'storyblok-js-client'
import styled from 'styled-components'

import { OpenEveningThumb } from 'components/blocks/sections/directories/thumbs/OpenEveningThumb'
import { Button, Tag } from 'components/ui'
import { Container } from 'components/ui/deprecated/Layout'
import { Text } from 'components/ui/deprecated/Text'
import { useDataContext } from 'lib/contexts/dataContext'
import useDrawer from 'lib/hooks/useDrawer'
import { Storyblok } from 'lib/storyblok'
import {
  LatestEntriesStoryblok,
  OpenEveningStoryblok,
} from 'lib/storyblok/types'
import {
  getAnchorFromCmsLink,
  getStartsWith,
  getStoryblokCacheValue,
  textByLine,
} from 'lib/utils/content'

type Props = {
  block: LatestEntriesStoryblok
}

export const LatestEvents = ({
  block,
  ...props
}: Props): JSX.Element | null => {
  const {
    isPreview,
    query: { lang },
  } = useRouter()

  const { drawer } = useDrawer()
  const isDrawer = !!drawer

  const {
    tag,
    title,
    description,
    button_label,
    button_link,
    item_amount = '3',
    starts_with,
  } = block

  const bySlugs = (starts_with || '')
    .split(';')
    .map((s) => getStartsWith(lang as string, s.trim()) + '*')
    .join(',')

  const { storyId } = useDataContext()
  const [data, setData] = useState<ISbStoryData<OpenEveningStoryblok>[] | null>(
    null
  )
  const [status, setStatus] = useState<'idle' | 'loading' | 'error'>('idle')

  const now = useRef(dayjs().format('YYYY-MM-DD HH:MM'))
  const fetch = useCallback(async () => {
    setStatus('loading')

    try {
      const {
        data: { stories },
      } = await Storyblok.get('cdn/stories', {
        version: isPreview ? 'draft' : 'published',
        content_type: 'open-evening',
        page: 1,
        per_page: Number(item_amount),
        by_slugs: bySlugs,
        excluding_ids: storyId?.toString(),
        sort_by: 'content.date_time:asc',
        filter_query: {
          date_time: {
            gt_date: now.current,
          },
        },
        cv: getStoryblokCacheValue(isPreview),
      })

      setStatus('idle')
      setData(stories)
    } catch (error) {
      setStatus('error')
    }
  }, [bySlugs, isPreview, item_amount, storyId])

  useEffect(() => {
    fetch()
  }, [fetch])

  const itemsToShow = isDrawer ? 2 : +item_amount

  const ContentWrapper: React.FC<{
    children: ReactNode
  }> = ({ children }) => {
    return <Grid itemsAmount={itemsToShow}>{children}</Grid>
  }

  if (status === 'error') return null

  if (status === 'idle' && data?.length === 0) return null

  return (
    <Wrapper isDrawer={isDrawer} itemsAmount={+item_amount} {...props}>
      <Header itemsAmount={+item_amount}>
        <div className="max-w-[650px]">
          {tag && <Tag className="mb-4">{tag}</Tag>}
          {title && <h2 className="text-title-large font-regular">{title}</h2>}
        </div>

        <div>
          {description && +item_amount == 4 && (
            <div className="mb-5">
              {textByLine(description, (part) => (
                <Text as="p" variant="twenty">
                  {part}
                </Text>
              ))}
            </div>
          )}

          {button_label && button_link && (
            <Button
              as="a"
              variant="outline"
              icon="arrow-right"
              iconPosition="right"
              rel={getAnchorFromCmsLink(button_link).rel}
              href={getAnchorFromCmsLink(button_link).href}
              target={getAnchorFromCmsLink(button_link).target}
            >
              {button_label}
            </Button>
          )}
        </div>
      </Header>

      {status === 'loading' ? (
        <ContentWrapper>
          {[...new Array(+item_amount)].map((_, index) => (
            <ContentLoader
              key={index}
              viewBox="0 0 620 170"
              preserveAspectRatio="none"
            >
              <rect x="0" y="0" rx="4" ry="4" width="620" height="170" />
            </ContentLoader>
          ))}
        </ContentWrapper>
      ) : (
        <ContentWrapper>
          {data?.slice(0, itemsToShow).map((item) => (
            <OpenEveningThumb key={item.id} item={item} />
          ))}
        </ContentWrapper>
      )}
    </Wrapper>
  )
}

const Wrapper = styled(Container)<{ itemsAmount: number; isDrawer?: boolean }>`
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  padding-left: 1.25rem;
  padding-right: 1.25rem;

  ${({ theme }) => theme.media.md} {
    padding-left: ${({ isDrawer }) => (isDrawer ? '2rem' : '5rem')};
    padding-right: ${({ isDrawer }) => (isDrawer ? '2rem' : '5rem')};
  }

  ${({ theme }) => theme.media.lg} {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }

  ${({ theme }) => theme.media.xl} {
    display: ${({ itemsAmount }) => (itemsAmount == 4 ? 'grid' : 'flex')};
    grid-template-columns: 430px 1fr;
    gap: ${({ itemsAmount }) => (itemsAmount == 4 ? '3.5rem' : '0')};
  }
`

const Header = styled.div<{ itemsAmount: number }>`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: space-between;

  gap: 1rem;

  margin-bottom: 1.25rem;

  align-items: flex-start;

  ${({ theme }) => theme.media.xl} {
    flex-direction: ${({ itemsAmount }) =>
      itemsAmount == 4 ? 'column' : 'row'};
    align-items: ${({ itemsAmount }) =>
      itemsAmount == 4 ? 'flex-start' : 'center'};

    margin-bottom: ${({ itemsAmount }) => (itemsAmount == 4 ? '0' : '2.5rem')};
  }
`

const Grid = styled.ul<{ itemsAmount: number }>`
  display: grid;
  grid-gap: 1.25rem;

  grid-template-columns: 1fr;

  ${({ theme }) => theme.media.md} {
    display: grid;
    grid-gap: 1.25rem;

    grid-template-columns: 1fr 1fr;
  }

  ${({ theme }) => theme.media.xl} {
    & > *:not(:last-child) {
      margin-bottom: 0;
    }

    display: grid;
    grid-gap: 1.25rem;

    grid-template-columns: ${({ itemsAmount }) =>
      itemsAmount == 4 ? '1fr 1fr' : `repeat(${itemsAmount}, 1fr)`};
  }
`
