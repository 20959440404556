import React from 'react'

import { LatestEntriesStoryblok } from 'lib/storyblok/types'

import { LatestBlogPosts } from './LatestEntries/LatestBlogPosts'
import { LatestEvents } from './LatestEntries/LatestEvents'

type Props = {
  block: LatestEntriesStoryblok
}

export const LatestEntries = ({
  block,
  ...props
}: Props): JSX.Element | null => {
  switch (block.content_type) {
    case 'blog-post':
      return <LatestBlogPosts block={block} {...props} />
    case 'open-evening':
      return <LatestEvents block={block} {...props} />
    default:
      return null
  }
}
