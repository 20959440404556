import { HTMLAttributes } from 'react'
import ContentLoader from 'react-content-loader'
import { useTheme } from 'styled-components'

import { addAlphaToColor } from 'lib/style'

export const CardLoader = (
  props: HTMLAttributes<HTMLDivElement>
): JSX.Element => {
  const theme = useTheme()

  return (
    <div {...props}>
      <ContentLoader
        backgroundColor={addAlphaToColor(theme.colors.foreground.subtle, 40)}
        foregroundColor={addAlphaToColor(theme.colors.foreground.subtle, 10)}
        opacity={0.25}
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
        height="100%"
        width="100%"
      >
        <rect x="0" y="0" rx="4" ry="4" width="100" height="80" />
        <rect x="0" y="82" rx="2" ry="2" width="90" height="4" />
        <rect x="0" y="88" rx="2" ry="2" width="80" height="4" />
      </ContentLoader>
    </div>
  )
}
